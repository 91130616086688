
	import {
		defineComponent
	} from 'vue';
	export default defineComponent({
		name: 'iconfont',
		props: {
			iconClass: {
				type: String,
				required: true
			},
			iconStyle: String
		},
		computed: {
			iconName() {
				return '#' + this.iconClass;
			}
		}
	});
